import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import Cookie from 'js-cookie';

import Header from '../../components/layout/Header';
import BottomNav from '../../components/layout/BottomNav';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import { getProfileFields, updateProfile } from '../../actions/profileActions';
import { closeMenu } from '../../actions/appActions';

import { days, months } from '../../constants/dates';

import 'react-datepicker/dist/react-datepicker.css';
import './Profile.css';
import { AppAPI } from '../../services/api';

const Profile = () => {
    const dispatch = useDispatch();
    const profileSelector = useSelector((state) => state.profile);
    const authSelector = useSelector((state) => state.auth);
    const { isSubscribed } = authSelector;
    const { loading, errors, successMessage } = profileSelector;
    const appSelector = useSelector((state) => state.app);
    const { fieldOptions, fieldOptionsByOptionID, sportsListByUniversityID } = appSelector;
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [profileError, setProfileError] = useState(null);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [currentPassword, SetCurrentPassword] = useState('');
    const [password, setPassword] = useState('');

    const [collegeUniversity, setCollegeUniversity] = useState('');
    const [societies, setSocieties] = useState([]);
    const [societySelect, setSocietySelect] = useState('');

    const [isUniversity, setIsUniversity] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(
            updateProfile(
                firstName,
                lastName,
                email,
                phone,
                currentPassword,
                password,
                collegeUniversity,
                societies
            )
        );
    };

    useEffect(() => {
        dispatch(closeMenu());
        dispatch(getProfileFields());
        setLoadingProfile(true);
        setProfileError(null);
        async function loadProfile() {
            try {
                const { data } = await AppAPI.get('/app_handler.php', {
                    headers: {
                        'WG-Method': 'LOAD_PROFILE',
                        'WG-Key': Cookie.get('accessToken'),
                    },
                });

                if (data) {
                    setFirstName(data?.firstname);
                    setLastName(data?.lastname);
                    setEmail(data?.email);
                    setPhone(data?.phone);
                    setCollegeUniversity(data?.college_or_university);
                    if (
                        data?.college_or_university === '26' ||
                        data?.college_or_university === '27' ||
                        data?.college_or_university === '28' ||
                        data?.college_or_university === '29' ||
                        data?.college_or_university === '64' ||
                        data?.college_or_university === '63'
                    ) {
                        setIsUniversity(true);
                    }
                    setSocieties(
                        data?.sports_team_or_society
                            ? data?.sports_team_or_society
                            : []
                    );
                }
            } catch (error) {
                setProfileError(
                    'Could not load profile. Please check your connection.'
                );
            }
            setLoadingProfile(false);
        }

        loadProfile();
    }, [dispatch]);

    const handleCollegeUniversityChange = (e) => {
        const { value } = e.target;

        setCollegeUniversity(value);

        if (
            value === '26' ||
            value === '27' ||
            value === '28' ||
            value === '29' || 
            value === '64' ||
            value === '63'
        ) {
            setIsUniversity(true);
        } else {
            setIsUniversity(false);
        }
    };

    const handleSocietiesChange = (e) => {
        const { value } = e.target;

        setSocieties((prev) => [...prev, value]);
    };

    const handleRemoveSociety = (id) => {
        if (id !== '') {
            setSocieties(
                (prev) => [...prev.filter((society) => society !== id)],
                () => {
                    setSocietySelect('');
                }
            );
        }
    };

    if (profileError) {
        return (
            <Fragment>
                <Header />
                <Menu />
                <Content style={{ marginTop: isSubscribed ? '120px' : '87px' }}>
                    <h1>My Profile</h1>
                    <div className="not-found">{profileError}</div>
                </Content>
                <BottomNav />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Header gold />
            <Menu />
            <Content style={{ marginTop: isSubscribed ? '120px' : '87px' }}>
                <h1>My Profile</h1>
                {successMessage && (
                    <div className="profile__success">{successMessage}</div>
                )}
                {loadingProfile ? (
                    <ClipLoader />
                ) : (
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label className="form__label" htmlFor="firstName">
                                First Name
                            </label>
                            <input
                                className="form__input"
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={firstName}
                                autoComplete={false}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <div className="error">
                                {errors?.firstname?.message}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="lastName">
                                Last Name
                            </label>
                            <input
                                className="form__input"
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                autoComplete={false}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <div className="error">
                                {errors?.lastname?.message}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="email">
                                Email
                            </label>
                            <input
                                className="form__input"
                                type="text"
                                id="email"
                                name="email"
                                value={email}
                                autoComplete={false}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="error">
                                {errors?.email?.message}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="phone">
                                Phone
                            </label>
                            <input
                                className="form__input"
                                type="text"
                                id="phone"
                                name="phone"
                                value={phone}
                                autoComplete={false}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <div className="error">
                                {errors?.phone?.message}
                            </div>
                        </div>
                        <div className="form-group">
                            <label
                                className="form__label"
                                htmlFor="day"
                            >
                                College or University
                            </label>
                            <select
                                className="form__select"
                                style={{ width: '100%' }}
                                name="collegeUniversity"
                                value={collegeUniversity}
                                onChange={handleCollegeUniversityChange}
                            >
                                <option value="">
                                    -- Please select an option --
                                </option>
                                {fieldOptions?.college_or_university?.map(
                                    (field) => (
                                        <option
                                            value={
                                                field?.field_option_id
                                            }
                                        >
                                            {field?.option_name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        {isUniversity && fieldOptions?.[sportsListByUniversityID[collegeUniversity]] && (
                            <React.Fragment>
                                <div className="form-group">
                                    <label
                                        className="form__label"
                                        htmlFor="day"
                                    >
                                        Sport team or Society(s)
                                    </label>
                                    <select
                                        className="form__select"
                                        style={{ width: '100%' }}
                                        name="societies"
                                        value={societySelect}
                                        onChange={handleSocietiesChange}
                                    >
                                        <option value="">
                                            -- Please select an option
                                            --
                                        </option>
                                        {fieldOptions?.[sportsListByUniversityID[collegeUniversity]]?.map(
                                            (field) => (
                                                <option
                                                    value={
                                                        field?.field_option_id
                                                    }
                                                >
                                                    {field?.option_name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                                {societies.length > 0 && (
                                    <div className="form__societies">
                                        {societies.map((society) => (
                                            <div className="society">
                                                <span>
                                                    {fieldOptionsByOptionID &&
                                                        fieldOptionsByOptionID[
                                                            society
                                                        ]}
                                                </span>
                                                <span
                                                    className="close"
                                                    onClick={() =>
                                                        handleRemoveSociety(
                                                            society
                                                        )
                                                    }
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={
                                                                2
                                                            }
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        <div className="form-group">
                            <label className="form__label" htmlFor="currentPassword">
                                Current Pin
                            </label>
                            <input
                                className="form__input"
                                type="password"
                                id="currentPassword"
                                name="currentPassword"
                                value={currentPassword}
                                autoComplete={false}
                                onChange={(e) => SetCurrentPassword(e.target.value)}
                            />
                            <div className="error">
                                {errors?.current_password?.message}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="password">
                                New Pin
                            </label>
                            <input
                                className="form__input"
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                autoComplete={false}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="error">
                                {errors?.password?.message}
                            </div>
                        </div>
                        <div className="form__buttons">
                            <button
                                aria-label="Update profile"
                                className="form__button form__button--inline"
                                type="submit"
                            >
                                Update profile{' '}
                                <PulseLoader
                                    loading={loading}
                                    color={'#ffffff'}
                                    css={'margin-left: 8px'}
                                    size={5}
                                />
                            </button>
                        </div>
                    </form>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    );
};

export default Profile;
